/** @format */

import { observable, action, computed, toJS } from "mobx";
import IXY from "../../types/IXY";
import * as Stores from "./IStores";
export class HoverStore implements Stores.IHover {
  rootStore: Stores.IRootBlock;
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable hoveringDot: string = "";
  @observable hoveringSeg: string = "";
  @observable hoveringRegion: string = "";

  @computed
  get activeHoverDots(): boolean {
    return true;
  }
  @computed
  get activeHoverSeg(): boolean {
    if (this.rootStore.Input.dragging) {
      return false;
    }
    return true;
  }
  @computed
  get activeHoverRegion(): boolean {
    return true;
  }
  @action
  setDotEnter = (handleKey: string) => {
    console.log("HOVER Dot: " + handleKey);
    this.hoveringDot = handleKey;
    this.hoveringSeg = "";
    this.hoveringRegion = "";
  };
  @action
  setDotLeave = () => {
    console.log("UNHOVER Dot");
    this.hoveringDot = "";
  };

  @action
  setSegEnter = (handleKey: string) => {
    console.log("HOVER Seg: " + handleKey);
    this.hoveringDot = "";
    this.hoveringSeg = handleKey;
    this.hoveringRegion = "";
  };
  @action
  setSegLeave = () => {
    console.log("UNHOVER seg");
    this.hoveringSeg = "";
  };

  @action
  setRegionEnter = (handleKey: string) => {
    console.log("HOVER region: " + handleKey);
    this.hoveringDot = "";
    this.hoveringSeg = "";
    this.hoveringRegion = handleKey;
  };
  @action
  setRegionLeave = () => {
    console.log("UNHOVER region");
    this.hoveringRegion = "";
  };
  @computed
  get isHoveringAny(): boolean {
    return (
      this.hoveringDot !== "" ||
      this.hoveringSeg !== "" ||
      this.hoveringRegion !== ""
    );
  }
  @computed
  get isHoveringDot(): boolean {
    return this.hoveringDot !== "";
  }
  @computed
  get isHoveringSeg(): boolean {
    return this.hoveringSeg !== "";
  }
  @computed
  get isHoveringRegion(): boolean {
    return this.hoveringRegion !== "";
  }
}
