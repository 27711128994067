/** @format */

import IXY from "../types/IXY";
import IGraph from "../types/IGraph";
import IGridPolygon from "../types/IGridPolygon";
export function FindNextClockwiseVertex(
  previousPoint: string,
  targetPoint: string,
  graph: IGraph
): string {
  const prevXY = graph.vertices.get(previousPoint)!;
  const targetXY = graph.vertices.get(targetPoint)!;

  const allOtherPointKeys: string[] = graph.adjacency
    .get(targetPoint)!
    .filter((x) => x !== previousPoint);

  const slopePrev = AngleCircle(targetXY, prevXY);

  const slopeMap = new Map<number, string>();
  allOtherPointKeys.forEach((j) => {
    const jXY = graph.vertices.get(j)!;
    slopeMap.set(AngleCircle(targetXY, jXY), j);
  });

  const otherAngles: number[] = Array.from(slopeMap.keys());
  const chosenAngle = Clockwise(slopePrev, otherAngles);
  const vertexFromChosenAngle = slopeMap.get(chosenAngle)!;
  return vertexFromChosenAngle;
}

export function Clockwise(startAngle: number, optionAngles: number[]): number {
  // initial condition, we cannot move to the same angle we started on
  if (optionAngles.indexOf(startAngle) !== -1) {
    return Clockwise(startAngle, optionAngles.filter((x) => x !== startAngle));
  }
  const sorted = optionAngles.sort((a, b) => {
    return a - b;
  });
  const valuesOverStart = sorted.filter((x) => x > startAngle);
  const smallestOver = valuesOverStart[0];
  const prev = sorted.indexOf(smallestOver);
  let next = prev - 1;
  if (next < 0) {
    next = sorted.length - 1;
  }
  return sorted[next];
}
function Angle(target: IXY, partner: IXY): number {
  const normalized = { x: partner.y - target.y, y: partner.x - target.x };
  const radians = Math.atan2(normalized.x, normalized.y);
  return toDegrees(radians);
}
function toDegrees(radians: number): number {
  return (radians * 180) / Math.PI;
}
export function AngleCircle(target: IXY, partner: IXY): number {
  const rawDegAngle = Angle(target, partner);
  return rawDegAngle < 0 ? rawDegAngle + 360 : rawDegAngle;
}
export function AngleHeading(target: IXY, partner: IXY): number {
  return toHeadingAngle(Angle(target, partner));
}

function toHeadingAngle(angle: number): number {
  let rawDegAngle = angle;
  rawDegAngle = rawDegAngle < -180 ? rawDegAngle + 360 : rawDegAngle;
  rawDegAngle = rawDegAngle > 180 ? rawDegAngle - 360 : rawDegAngle;
  return rawDegAngle;
}
export function HeadingChange(a: IXY, b: IXY, c: IXY): number {
  const firstAngle = AngleHeading(a, b);
  const secondAngle = AngleHeading(b, c);
  const diff = secondAngle - firstAngle;
  return toHeadingAngle(diff);
}
