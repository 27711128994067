/** @format */

import { observable, action, computed, toJS } from "mobx";
import IXY from "../../types/IXY";
import IGridLine from "../../types/IGridLine";
import * as Bounds from "../../dotGrid/Bounds";
import * as Stores from "./IStores";
import { PointReverse } from "../../math/Hashing";
export class InputStore implements Stores.IInput {
  rootStore: Stores.IRootBlock;
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable dragging: boolean = false;
  @observable click: IXY = { x: 0, y: 0 };
  @observable clickLeft: boolean = false;
  @observable clickMiddle: boolean = false;
  @observable clickRight: boolean = false;
  @observable clickShift: boolean = false;
  @observable clickAlt: boolean = false;
  @observable clickCtrl: boolean = false;

  @observable dragStartPoint: IXY = { x: 0, y: 0 };
  @observable dragEndPoint: IXY = { x: 0, y: 0 };

  dragDelta: IXY = { x: 0, y: 0 };

  @action
  setClick = (options: Stores.IInputClickOptions) => {
    this.clickShift = options.shift;
    this.clickAlt = options.alt;
    this.clickCtrl = options.ctrl;
    this.clickMiddle = options.middle;
    this.clickRight = options.right;
    this.clickLeft = this.isLeftClick(options);

    if (this.rootStore.Hover.isHoveringDot) {
      this.click = options.position;
      this.dragging = true;
      this.dragStartPoint = PointReverse(this.rootStore.Hover.hoveringDot);
      this.dragEndPoint = PointReverse(this.rootStore.Hover.hoveringDot);
    } else {
      this.dragStartPoint = { x: 0, y: 0 };
      this.dragEndPoint = { x: 0, y: 0 };
    }

    if (this.rootStore.Hover.isHoveringSeg) {
      if (this.clickAlt) {
        const segKey = this.rootStore.Hover.hoveringSeg;
        this.rootStore.Graphic.removeLine(segKey);
      }
    }
  };

  @action
  setDrag = (position: IXY) => {
    if (this.dragging) {
      this.dragDelta = pointDelta(position, this.click);
      if (this.rootStore.Hover.isHoveringDot) {
        console.log("blockStore-setdrag-ishovering");
        this.dragEndPoint = PointReverse(this.rootStore.Hover.hoveringDot);
      } else {
        this.dragEndPoint = addXY(this.dragDelta, this.dragStartPoint);
        console.log(this.dragEndPoint);
      }
    }
  };
  @action
  setRelease = () => {
    this.clearClickType();
    if (this.rootStore.Hover.isHoveringDot) {
      const potentialLine: IGridLine = {
        begin: this.dragStartPoint,
        end: this.dragEndPoint,
        solid: true
      };
      if (Bounds.IsLineWithinBounds(potentialLine)) {
        this.rootStore.Graphic.addLine(potentialLine);
      }

      this.dragStartPoint = { x: 0, y: 0 };
      this.dragEndPoint = { x: 0, y: 0 };
    }
  };

  clearClickType = () => {
    this.clickLeft = false;
    this.clickMiddle = false;
    this.clickRight = false;
    this.clickShift = false;
    this.clickAlt = false;
    this.clickCtrl = false;
    this.dragging = false;
  };

  isLeftClick = (options: Stores.IInputClickOptions) => {
    return (
      !options.shift &&
      !options.alt &&
      !options.ctrl &&
      !options.middle &&
      !options.right
    );
  };
}

function addXY(a: IXY, b: IXY): IXY {
  return { x: a.x + b.x, y: a.y + b.y };
}
function pointDelta(a: IXY, b: IXY): IXY {
  return { x: a.x - b.x, y: a.y - b.y };
}
