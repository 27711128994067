/** @format */

import { observable, action, computed, toJS } from "mobx";
import IXY from "../../types/IXY";
import IGridLine from "../../types/IGridLine";
import IGridPolygon from "../../types/IGridPolygon";
import IGridPoint, { PointType } from "../../types/IGridPoint";
import {
  FindAllIntersections,
  FindQuarterInchPoints
} from "../../math/IntersectionCalc";
import { ReduceAll, RoundGridLine } from "../../math/SegmentSimplification";
import { FindAllPolygons } from "../../math/PolygonFinder";
import { ExteriorRemover } from "../../math/HeadingFinder";
import * as Stores from "./IStores";
import * as Hashing from "../../math/Hashing";
export class GraphicStore implements Stores.IGraphic {
  rootStore: Stores.IRootBlock;
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @action
  addLine = (newGridLine: IGridLine) => {
    const copyLine: IGridLine = {
      begin: toJS(newGridLine.begin),
      end: toJS(newGridLine.end),
      solid: toJS(newGridLine.solid)
    };
    this.gridLines = ReduceAll(toJS(this.gridLines), RoundGridLine(copyLine));
  };

  @action
  removeLine = (segKeyHash: string) => {
    const jsGridLines = toJS(this.gridLines);
    const subSetGridLines = jsGridLines.filter((line) => {
      const iKey = Hashing.LineKey(line);
      const match: boolean = iKey === segKeyHash;
      return !match;
    });
    this.gridLines = subSetGridLines;
  };

  @observable regions: IGridPolygon[] = [];
  @observable gridLines: IGridLine[] = [];
  @computed
  get solidGridLines(): IGridLine[] {
    return this.gridLines.filter((line) => line.solid === true);
  }

  @computed
  get guideGridLines(): IGridLine[] {
    return this.gridLines.filter((line) => line.solid !== true);
  }

  @computed
  get intersectionPointsAll(): IGridPoint[] {
    const naiveIntersections = FindAllIntersections(this.gridLines);
    return naiveIntersections;
  }
  @computed
  get intersectionPointsSolidIsolated(): IGridPoint[] {
    return this.intersectionPointsAll.filter(
      (x) => x.Variety === PointType.SolidIsolated
    );
  }
  @computed
  get intersectionPointsSolidVertex(): IGridPoint[] {
    return this.intersectionPointsAll.filter(
      (x) => x.Variety === PointType.SolidVertex
    );
  }
  @computed
  get intersectionPointsSolidBisect(): IGridPoint[] {
    return this.intersectionPointsAll.filter(
      (x) => x.Variety === PointType.SolidBisection
    );
  }
  @computed
  get detectedPolygons(): IGridPolygon[] {
    return ExteriorRemover(FindAllPolygons(toJS(this.solidGridLines)));
  }
  @computed
  get edgeRulerPoints(): IGridPoint[] {
    return FindQuarterInchPoints(this.rootStore.Pref.inches);
  }
}
