/** @format */

export interface ITransform {
  rotate: number;
  mirror: MirrorDir;
}
export enum MirrorDir {
  None = 0,
  FlipX = 1,
  FlipY = 2
}
