/** @format */

import IColorPolygon from "../types/IColorPolygon";
import IBlockTemplate from "../types/IBlockTemplate";
import { MirrorDir } from "../types/ITransform";

export const HST: IBlockTemplate = {
  transform: {
    rotate: 0,
    mirror: MirrorDir.FlipX
  },
  regions: [
    {
      // note: "Upper Left Triangle",
      points: [
        { x: 0, y: 0 },
        { x: 0, y: 1000 },
        { x: 1000, y: 0 },
        { x: 0, y: 0 }
      ],
      fabric: 0
    },
    {
      // note: "Lower Right Triangle",
      points: [
        { x: 1000, y: 1000 },
        { x: 0, y: 1000 },
        { x: 1000, y: 0 },
        { x: 1000, y: 1000 }
      ],
      fabric: 1
    }
  ]
};

export const FOUR_SQUARE: IBlockTemplate = {
  transform: {
    rotate: 90,
    mirror: MirrorDir.FlipX
  },
  regions: [
    {
      // note: "Top Left",
      points: [
        { x: 0, y: 0 },
        { x: 0, y: 500 },
        { x: 500, y: 500 },
        { x: 500, y: 0 },
        { x: 0, y: 0 }
      ],
      fabric: 0
    },
    {
      // note: "Top Right",
      points: [
        { x: 0, y: 500 },
        { x: 0, y: 1000 },
        { x: 500, y: 1000 },
        { x: 500, y: 500 },
        { x: 0, y: 500 }
      ],
      fabric: 1
    },
    {
      // note: "Bottom Left",
      points: [
        { x: 500, y: 0 },
        { x: 500, y: 500 },
        { x: 1000, y: 500 },
        { x: 1000, y: 0 },
        { x: 500, y: 0 }
      ],
      fabric: 1
    },
    {
      // note: "Bottom Right",
      points: [
        { x: 500, y: 500 },
        { x: 500, y: 1000 },
        { x: 1000, y: 1000 },
        { x: 1000, y: 500 },
        { x: 500, y: 500 }
      ],
      fabric: 0
    }
  ]
};

export const All: IBlockTemplate[] = [HST, FOUR_SQUARE];
