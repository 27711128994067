/** @format */

import { ITransform, MirrorDir } from "../types/ITransform";

export default function ToString(transform: ITransform): string {
  return ToStringBounds(transform, 1000, 1000);
}

export function ToStringBounds(
  transform: ITransform,
  boundsX: number,
  boundsY: number
) {
  // https://stackoverflow.com/questions/23899718/scale-and-mirror-svg-object
  let mirrorString: string = "";
  switch (transform.mirror) {
    case MirrorDir.None:
      mirrorString = "";
      break;
    case MirrorDir.FlipX:
      mirrorString = "translate(" + boundsX + ",0) scale(-1, 1)";
      break;
    case MirrorDir.FlipY:
      mirrorString = "translate(0," + boundsY + ") scale(1, -1)";
      break;
  }

  let rotateString: string =
    "rotate(" + transform.rotate + " " + boundsX / 2 + " " + boundsY / 2 + ")";

  return [mirrorString, rotateString].join(" ");
}
