/** @format */

import * as React from "react";
import ICoordPair from "../types/ICoordPair";
import { propTypes } from "mobx-react";
import { BOUND_MAX, BOUND_MIN } from "./Bounds";
export interface IRulerProps {
  inches: number;
}

const outlineColor = "rgb(22, 90, 201)";
const innerColor = "rgb(200,200,200)";
const height = 50;

function getPath(coordPair: ICoordPair) {
  return `M ${coordPair.begin.x} ${coordPair.begin.y} L ${coordPair.end.x} ${
    coordPair.end.y
  }`;
}
function getGap(inches: number): number {
  return BOUND_MAX / inches;
}
export default function Ruler(props: IRulerProps) {
  return (
    <svg
      className="ruler"
      x={-25}
      y={-1 * height}
      width="1050"
      viewBox={"-25 0 1050 50"}
      height={height}
    >
      <g className="full-inch-marks">
        {Array.from({ length: props.inches + 1 }, (v, k) => k).map((inch) => (
          <g className={"inch-" + inch} key={"inch-" + inch}>
            <path
              key={"inch-mark-" + inch}
              d={getPath({
                begin: { x: inch * getGap(props.inches), y: 0 },
                end: { x: inch * getGap(props.inches), y: 50 }
              })}
              stroke="black"
              strokeLinecap="square"
              strokeWidth="5"
              fill="black"
            />
            <circle
              cx={inch * getGap(props.inches)}
              cy={25}
              r={10}
              fill="white"
              stroke="black"
              strokeWidth={3}
            />
            <text
              x={inch * getGap(props.inches)}
              y="25"
              textAnchor="middle"
              stroke="black"
              strokeWidth="2px"
              dy=".3em"
            >
              {inch}
            </text>
          </g>
        ))}
      </g>
      <g className="half-inch-marks">
        {Array.from({ length: props.inches }, (v, k) => k).map((inch) => (
          <g className={"half-inch-" + inch} key={"half-inch-" + inch}>
            <path
              key={"half-inch-mark-" + inch}
              d={getPath({
                begin: { x: (inch + 0.5) * getGap(props.inches), y: 0 },
                end: { x: (inch + 0.5) * getGap(props.inches), y: 50 }
              })}
              stroke="black"
              strokeLinecap="square"
              strokeWidth="3"
              strokeDasharray="10,10"
              fill="black"
            />
          </g>
        ))}
      </g>
      <g className="quarter-inch-marks">
        {Array.from({ length: props.inches }, (v, k) => k).map((inch) => (
          <g className={"quarter-inch-" + inch} key={"quarter-inch-" + inch}>
            <path
              key={"quarter-inch-mark-a-" + inch}
              d={getPath({
                begin: { x: (inch + 0.25) * getGap(props.inches), y: 0 },
                end: { x: (inch + 0.25) * getGap(props.inches), y: 50 }
              })}
              stroke="black"
              strokeLinecap="square"
              strokeWidth="1"
              strokeDasharray="5,5"
              fill="black"
            />
            <path
              key={"quarter-inch-mark-b-" + inch}
              d={getPath({
                begin: { x: (inch + 0.75) * getGap(props.inches), y: 0 },
                end: { x: (inch + 0.75) * getGap(props.inches), y: 50 }
              })}
              stroke="black"
              strokeLinecap="square"
              strokeWidth="1"
              strokeDasharray="5,5"
              fill="black"
            />
          </g>
        ))}
      </g>
    </svg>
  );
}
