/** @format */

import React from "react";
import clrs from "colors.css";
import { IRootBlock } from "../store/block/IStores";
import { inject, observer } from "mobx-react";
export interface IHandleProps {
  id: any;
  x: number;
  y: number;
  blockStore?: IRootBlock;
}

const basicRadius = 10;
const expandedRadius = 30;
@inject("blockStore")
@observer
class DotHandle extends React.Component<IHandleProps> {
  constructor(props, context) {
    super(props, context);

    this.hover = this.hover.bind(this);
    this.unhover = this.unhover.bind(this);
  }

  hover(e: any) {
    this.props.blockStore!.Hover.setDotEnter(this.props.id);
  }
  unhover(e: any) {
    this.props.blockStore!.Hover.setDotLeave();
  }

  render() {
    return (
      <circle
        className="handle"
        id={this.props.id}
        cx={this.props.x}
        cy={this.props.y}
        r={
          this.props!.blockStore!.Hover.hoveringDot === this.props.id
            ? expandedRadius
            : basicRadius
        }
        fill={clrs.yellow}
        fillOpacity={0.4}
        onMouseOver={this.hover}
        onMouseLeave={this.unhover}
      />
    );
  }
}

export default DotHandle;
