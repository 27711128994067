/** @format */

import IGridLine from "../types/IGridLine";
import IGridPoint, { PointType } from "../types/IGridPoint";
import IXY from "../types/IXY";
import * as Calc from "./IntersectionCalc";

export function ReduceAll(
  allLines: IGridLine[],
  newGridLine: IGridLine
): IGridLine[] {
  const nonSolids = allLines
    .filter((line) => line.solid !== true)
    .map((y) => OrderGridLine(y));
  let solids = allLines
    .filter((line) => line.solid === true)
    .map((y) => OrderGridLine(y));
  const newLine = OrderGridLine(newGridLine);

  solids.push(newLine);
  const tempAllInters = Calc.FindAllIntersections(solids);
  const allInters = tempAllInters.filter(
    (x) => x.Variety === PointType.SolidBisection
  );
  allInters.forEach((x) => (solids = ReduceSolids(solids, x)));

  return nonSolids.concat(solids);
}

export function ReduceSolids(
  allSolidLines: IGridLine[],
  bigPoint: IXY
): IGridLine[] {
  const toReturn: Map<string, IGridLine> = new Map();

  allSolidLines.forEach((x) =>
    SplitLineIfCrossesPoint(x, bigPoint).forEach((y) =>
      toReturn.set(GenerateLineKey(y), y)
    )
  );
  return Array.from(toReturn.values());
}
function GenerateLineKey(line: IGridLine): string {
  return (
    "k-" +
    line.begin.x +
    "-" +
    line.begin.y +
    "-" +
    line.end.x +
    "-" +
    line.end.y
  );
}

export function isPointAlongSegment(point: IXY, begin: IXY, end: IXY): boolean {
  if (point.x === begin.x && point.y === begin.y) {
    return true; // point is beginpoint
  }
  if (point.x === end.x && point.y === end.y) {
    return true; // point is endpoint
  }
  const betweenX =
    (begin.x <= point.x && point.x <= end.x) ||
    (begin.x >= point.x && point.x >= end.x);
  const betweenY =
    (begin.y <= point.y && point.y <= end.y) ||
    (begin.y >= point.y && point.y >= end.y);

  if (!betweenX || !betweenY) {
    return false;
  }

  const runEnd = end.x - begin.x;
  const riseEnd = end.y - begin.y;
  const runPoint = point.x - begin.x;
  const risePoint = point.y - begin.y;

  // check vertical alignment
  if (runEnd === 0 && runPoint === 0) {
    return true; // vertical, but aligned
  }

  if (runEnd === 0 || runPoint === 0) {
    return false; // if one of them is vertical, they must both be to match
  }
  const endAngle = riseEnd / runEnd;
  const pointAngle = risePoint / runPoint;

  const diff = endAngle - pointAngle;

  const TOLERANCE = 0.01;
  if (diff >= -1 * TOLERANCE && diff <= TOLERANCE) {
    return true;
  }
  return false;
}
export function SplitLineIfCrossesPoint(
  longLine: IGridLine,
  bigPoint: IXY
): IGridLine[] {
  if (bigPoint.x === longLine.begin.x && bigPoint.y === longLine.begin.y) {
    return [longLine];
  }
  if (bigPoint.x === longLine.end.x && bigPoint.y === longLine.end.y) {
    return [longLine];
  }
  if (isPointAlongSegment(bigPoint, longLine.begin, longLine.end)) {
    return [
      { begin: longLine.begin, end: bigPoint, solid: longLine.solid },
      { begin: bigPoint, end: longLine.end, solid: longLine.solid }
    ].map((x) => OrderGridLine(x));
  }
  return [longLine];
}

export function RoundGridLine(line: IGridLine): IGridLine {
  const toReturn: IGridLine = {
    begin: { x: Math.round(line.begin.x), y: Math.round(line.begin.y) },
    end: { x: Math.round(line.end.x), y: Math.round(line.end.y) },
    solid: line.solid
  };
  return toReturn;
}
export function OrderGridLine(line: IGridLine): IGridLine {
  // left to right priority:
  if (line.begin.x < line.end.x) {
    return line;
  }
  if (line.begin.x === line.end.x && line.begin.y <= line.end.y) {
    return line;
  }
  const toReturn = { ...line };
  toReturn.begin = line.end;
  toReturn.end = line.begin;
  return toReturn;
}
