/** @format */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
import Region from "../dotGrid/Region";
import { BOUND_MIN, BOUND_MAX } from "../dotGrid/Bounds";
import BlockImage from "./BlockImage";
import ICoordPair from "../types/ICoordPair";
import * as PotentialBlockList from "./PotentialBlockList";
import IXY from "../types/IXY";
import IColorPolygon from "../types/IColorPolygon";
import IBlockTemplate from "../types/IBlockTemplate";
interface IGridProps {
  blockList: IBlockTemplate[];
}

export class MenuBlocks extends React.Component<IGridProps> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <table>
        <tbody>
          <tr>
            <th>Block List</th>
          </tr>
          {this.props.blockList.map((ipoly, i) => (
            <tr key={"block-option-" + i}>
              <td>
                <div>
                  <BlockImage
                    x={0}
                    y={0}
                    width={100}
                    height={100}
                    template={ipoly}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
