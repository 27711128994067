/** @format */

import IGridPolygon from "../types/IGridPolygon";
import * as AngDet from "./AngleDetection";
import IXY from "../types/IXY";

export function IsInterior(poly: IGridPolygon): boolean {
  return FindTotalHeading(poly)! > 0;
}
export function ExteriorRemover(input: IGridPolygon[]): IGridPolygon[] {
  return input.filter((x) => IsInterior(x));
}

export function FindTotalHeading(poly: IGridPolygon): number | null {
  if (poly.points.length < 4) {
    return null;
  }
  if (!firstPointMatchesLastPoint(poly)) {
    return null;
  }
  let i = 0;
  let runningHeading: number = 0;
  for (i = 0; i < poly.points.length - 2; i++) {
    const p1 = poly.points[i];
    const p2 = poly.points[i + 1];
    const p3 = poly.points[i + 2];
    runningHeading += AngDet.HeadingChange(p1, p2, p3);
  }

  const u1 = poly.points[poly.points.length - 2];
  const u2 = poly.points[0];
  const u3 = poly.points[1];
  runningHeading += AngDet.HeadingChange(u1, u2, u3);

  return runningHeading;
}

function firstPointMatchesLastPoint(poly: IGridPolygon): boolean {
  return samePoint(poly.points[0], poly.points[poly.points.length - 1]);
}
function samePoint(a: IXY, b: IXY): boolean {
  return a.x === b.x && a.y === b.y;
}
