/** @format */

import * as React from "react";
import BlockHandle from "./BlockHandle";
import { PointKey } from "../math/Hashing";
import * as PATTERN from "./PotentialBlockList";
import { ColoredRegions } from "./ColoredRegions";
import { FabricChoices } from "../store/layout/RootQuiltStore";
import IBlockTemplate from "../types/IBlockTemplate";
import SvgTrans from "../math/SvgTransformation";
export interface IBlockImageProps {
  x: number;
  y: number;
  height: number;
  width: number;
  template: IBlockTemplate;
}

export default function BlockImage(props: IBlockImageProps) {
  return (
    <svg
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      role="img"
      viewBox="0 0 1000 1000"
      preserveAspectRatio="xMidYMid meet"
    >
      <ColoredRegions
        colors={FabricChoices}
        regions={props.template.regions}
        transform={SvgTrans(props.template.transform)}
      />
    </svg>
  );
}
