/** @format */

import * as React from "react";
import { ISelectState } from "./ISelectState";

/**  https://reactjs.org/docs/dom-elements.html#all-supported-svg-attributes */
class SelectTab extends React.Component<{}, ISelectState> {
  constructor(props: any) {
    super(props);
    this.state = {
      blockCountX: 4,
      blockCountY: 3,
      blockInches: 9,
      borderInches: 3,
      interiorSashing: 1
    };
  }

  render() {
    return (
      <div>
        <p>This tab will be to demo the select/multiselect capability</p>
        <svg>
          <rect />
        </svg>
      </div>
    );
  }
}

export default SelectTab;
