/** @format */

import * as React from "react";
import { IQuiltDimensions } from "./IQuiltDimensions";
import IXY from "../types/IXY";
import BlockImage from "./BlockImage";
import * as PotentialBlockList from "./PotentialBlockList";
/**  https://reactjs.org/docs/dom-elements.html#all-supported-svg-attributes */
class Outline extends React.Component<IQuiltDimensions> {
  scale = 10;
  constructor(props: IQuiltDimensions) {
    super(props);
  }

  block(coord: IXY) {
    const sashingOffsetX = coord.x * this.props.interiorSashing * this.scale;
    const sashingOffsetY = coord.y * this.props.interiorSashing * this.scale;
    return this.square(
      this.scale * this.props.borderInches +
        sashingOffsetX +
        this.scale * this.props.blockInches * coord.x,
      this.scale * this.props.borderInches +
        sashingOffsetY +
        this.scale * this.props.blockInches * coord.y,
      this.scale * this.props.blockInches,
      this.scale * this.props.blockInches
    );
  }
  border(borderWidth: number, totalHeight: number, totalWidth: number) {
    return (
      <g className="border">
        {this.square(0, 0, totalHeight, borderWidth, false)} //left
        {this.square(
          totalWidth - borderWidth,
          0,
          totalHeight,
          borderWidth,
          false
        )}
        //right
        {this.square(0, 0, borderWidth, totalWidth, false)}//top
        {this.square(
          0,
          totalHeight - borderWidth,
          borderWidth,
          totalWidth,
          false
        )}
        //bottom
      </g>
    );
  }
  square(
    x: number,
    y: number,
    height: number,
    width: number,
    block: boolean = true
  ) {
    const outlineColor = "rgb(22, 90, 201)";
    const innerColor = "rgb(60, 126, 234)";
    return (
      <g className="square" key={x * 10000 + y + "#" + height + "#" + width}>
        <rect
          className="square-outline"
          fill={outlineColor}
          width={width}
          height={height}
          x={x}
          y={y}
        />
        <rect
          className="square-fill"
          fill={innerColor}
          width={width - 2}
          height={height - 2}
          x={x + 1}
          y={y + 1}
        />
        {block ? (
          <BlockImage
            width={width - 2}
            height={height - 2}
            x={x + 1}
            y={y + 1}
            template={PotentialBlockList.FOUR_SQUARE}
          />
        ) : null}
      </g>
    );
  }

  render() {
    const widthBorder = this.scale * this.props.borderInches * 2;
    const widthBlocks =
      this.scale * this.props.blockInches * this.props.blockCountX;
    const widthSashing =
      (this.props.blockCountX - 1) * this.props.interiorSashing * this.scale;
    const totalWidth = widthBorder + widthBlocks + widthSashing;

    const heightBorder = this.scale * this.props.borderInches * 2;
    const heightBlocks =
      this.scale * this.props.blockInches * this.props.blockCountY;
    const heightSashing =
      (this.props.blockCountY - 1) * this.props.interiorSashing * this.scale;
    const totalHeight = heightBorder + heightBlocks + heightSashing;
    return (
      <svg
        className="chart"
        width={totalWidth}
        height={totalHeight}
        aria-labelledby="title desc"
        role="img"
      >
        {this.border(
          this.props.borderInches * this.scale,
          totalHeight,
          totalWidth
        )}
        {this.allBlocks()}
      </svg>
    );
  }
  allBlocks() {
    const allCoords: IXY[] = [];
    for (let ix = 0; ix < this.props.blockCountX; ix++) {
      for (let iy = 0; iy < this.props.blockCountY; iy++) {
        allCoords.push({ x: ix, y: iy });
      }
    }
    return <g>{allCoords.map((coord) => this.block(coord))}</g>;
  }
}

export default Outline;
