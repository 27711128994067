/** @format */

import * as React from "react";
import ICoordPair from "../types/ICoordPair";
import * as Hashing from "../math/Hashing";
export default function LineDashed(coordPair: ICoordPair) {
  const pathD = `M ${coordPair.begin.x} ${coordPair.begin.y} L ${
    coordPair.end.x
  } ${coordPair.end.y}`;
  return (
    <path
      d={pathD}
      stroke="grey"
      strokeLinecap="round"
      strokeWidth="1"
      strokeDasharray="5,5"
      fill="none"
    />
  );
}
