/** @format */

import * as React from "react";

import InputSize from "./svgCanvas/InputSize";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Grid from "./dotGrid/Grid";
import { Provider } from "mobx-react";
import { RootBlockStore } from "./store/block/RootBlockStore";
import DebugInfo from "./dotGrid/DebugInfo";
import { ThumbnailRegions } from "./dotGrid/ThumbnailRegions";
import SelectTab from "./select/SelectTab";
import ExperimentTab from "./experiment/ExperimentTab";
class App extends React.Component {
  private SingletonblockStore = new RootBlockStore();

  render() {
    return (
      <Provider blockStore={this.SingletonblockStore}>
        <div className="App">
          <Tabs defaultIndex={3}>
            <TabList>
              <Tab>Dots</Tab>
              <Tab>Calculator</Tab>
              <Tab>Select</Tab>
              <Tab>Experiment</Tab>
            </TabList>

            <TabPanel>
              <Grid />
              <ThumbnailRegions />
              <DebugInfo />
            </TabPanel>
            <TabPanel>
              <InputSize />
            </TabPanel>
            <TabPanel>
              <SelectTab />
            </TabPanel>
            <TabPanel>
              <ExperimentTab />
            </TabPanel>
          </Tabs>
        </div>
      </Provider>
    );
  }
}

export default App;
