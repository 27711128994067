/** @format */

import * as React from "react";
import IXY from "../types/IXY";
import ICoordPair from "../types/ICoordPair";
import IGridLine from "../types/IGridLine";
import Dot from "./Dot";
import { AllDots } from "./AllDots";
import { StaticLines } from "./StaticLines";
import { IRootBlock } from "../store/block/IStores";
import { DynamicLine } from "./DynamicLine";
import { inject, observer } from "mobx-react";
import "./DebugInfo.css";
import * as Poly from "../math/PolygonFinder";
import * as Simp from "../math/SegmentSimplification";
import * as Calc from "../math/IntersectionCalc";
import * as AngDet from "../math/AngleDetection";
import IGridPoly from "../types/IGridPolygon";
import * as HeadDet from "../math/HeadingFinder";
import * as Gmaker from "../math/GraphMaker";
interface IGridProps {
  blockStore?: IRootBlock;
}

const DebugInfo = inject("blockStore")(
  observer(
    class DebugInfoInner extends React.Component<IGridProps> {
      constructor(props) {
        super(props);
      }
      debugUnitTest() {}

      render() {
        return (
          <div>
            <button onClick={(e) => this.debugUnitTest()}>Run Test</button>
            <table>
              <tbody>
                <tr>
                  <th>Click</th>
                  <th>Dragging</th>
                  <th>LeftClick</th>
                  <th>MiddleClick</th>
                  <th>RightClick</th>
                  <th>ShiftClick</th>
                  <th>AltClick</th>
                  <th>CtrlClick</th>
                </tr>
                <tr>
                  <td>
                    x: {this.props.blockStore!.Input.click.x}, y:
                    {this.props.blockStore!.Input.click.y}
                  </td>
                  <td>
                    {this.props.blockStore!.Input.dragging ? "true" : "false"}
                  </td>
                  <td>
                    {this.props.blockStore!.Input.clickLeft ? "true" : "false"}
                  </td>
                  <td>
                    {this.props.blockStore!.Input.clickMiddle
                      ? "true"
                      : "false"}
                  </td>
                  <td>
                    {this.props.blockStore!.Input.clickRight ? "true" : "false"}
                  </td>
                  <td>
                    {this.props.blockStore!.Input.clickShift ? "true" : "false"}
                  </td>
                  <td>
                    {this.props.blockStore!.Input.clickAlt ? "true" : "false"}
                  </td>
                  <td>
                    {this.props.blockStore!.Input.clickCtrl ? "true" : "false"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th>Solid Segments</th>
                  <th>Guide Segments</th>
                </tr>
                <tr>
                  <td>
                    {this.props.blockStore!.Graphic.solidGridLines.length}
                  </td>
                  <td>
                    {this.props.blockStore!.Graphic.guideGridLines.length}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />

            <br />
          </div>
        );
      }
    }
  )
);
export default DebugInfo;
