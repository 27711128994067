/** @format */

import ICoordPair from "../types/ICoordPair";
import IGridLine from "../types/IGridLine";
import IXY from "../types/IXY";

export const BOUND_MIN = 0;
export const BOUND_MAX = 1000;

export function IsLineWithinBounds(t: IGridLine): boolean {
  return (
    t.begin.x >= BOUND_MIN &&
    t.begin.y <= BOUND_MAX &&
    t.end.x >= BOUND_MIN &&
    t.end.y <= BOUND_MAX
  );
}
export function IsEdgeSegment(p: ICoordPair): boolean {
  const horizontal: boolean = p.begin.y === p.end.y;
  const vertical: boolean = p.begin.x === p.end.x;
  if (!horizontal && !vertical) {
    return false;
  }
  if (horizontal) {
    if (p.begin.y === BOUND_MIN || p.begin.y === BOUND_MAX) {
      return true;
    }
  }
  if (vertical) {
    if (p.begin.x === BOUND_MIN || p.begin.x === BOUND_MAX) {
      return true;
    }
  }

  return false;
}

export function quarterGridLines(sections: number) {
  const spread = (BOUND_MAX - BOUND_MIN) / sections;
  const allPairs: IGridLine[] = [];
  for (let ix = 0; ix < sections; ix++) {
    const thisStart: IXY = { x: spread * ix, y: spread * 0 };
    const thisEnd: IXY = { x: spread * ix, y: spread * sections };
    allPairs.push({ begin: thisStart, end: thisEnd, solid: false });
  }
  for (let iy = 0; iy < sections; iy++) {
    const thisStart: IXY = { x: spread * 0, y: spread * iy };
    const thisEnd: IXY = { x: spread * sections, y: spread * iy };
    allPairs.push({ begin: thisStart, end: thisEnd, solid: false });
  }
  return allPairs;
}
export function OutlineSolidLines(): IGridLine[] {
  const spreadMin = BOUND_MIN;
  const spreadMax = BOUND_MAX;
  const allPairs: IGridLine[] = [];
  allPairs.push({
    begin: { x: spreadMin, y: spreadMin },
    end: { x: spreadMin, y: spreadMax },
    solid: true
  });

  allPairs.push({
    begin: { x: spreadMin, y: spreadMin },
    end: { x: spreadMax, y: spreadMin },
    solid: true
  });

  allPairs.push({
    begin: { x: spreadMax, y: spreadMin },
    end: { x: spreadMax, y: spreadMax },
    solid: true
  });

  allPairs.push({
    begin: { x: spreadMin, y: spreadMax },
    end: { x: spreadMax, y: spreadMax },
    solid: true
  });

  return allPairs;
}
