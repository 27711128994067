/** @format */
import { findSegmentIntersection } from "line-intersection";
import IXY from "../types/IXY";
import IGridPoint, { PointType } from "../types/IGridPoint";
import IGridLine from "../types/IGridLine";
import { OrderGridLine } from "./SegmentSimplification";
import * as Hashing from "./Hashing";
import { BOUND_MIN, BOUND_MAX } from "../dotGrid/Bounds";
export function FindAllIntersections(lines: IGridLine[]): IGridPoint[] {
  const cleanLines = lines.map((x) => OrderGridLine(x));
  const interHash: Map<string, IGridPoint> = new Map();

  // Add all solid segment endpoints
  const solidLines = cleanLines.filter((w) => w.solid);
  solidLines.forEach((z) => {
    interHash.set(Hashing.PointKey(z.begin), {
      x: z.begin.x,
      y: z.begin.y,
      Variety: PointType.SolidIsolated
    });
    interHash.set(Hashing.PointKey(z.end), {
      x: z.end.x,
      y: z.end.y,
      Variety: PointType.SolidIsolated
    });
  });

  // start looping through all the lines
  let i: number;
  for (i = 0; i < cleanLines.length; i++) {
    let j: number;
    for (j = i + 1; j < cleanLines.length; j++) {
      const comparisonArray: IXY[] = [
        cleanLines[i].begin,
        cleanLines[i].end,
        cleanLines[j].begin,
        cleanLines[j].end
      ];
      const result: IXY = findAnySegmentIntersection(comparisonArray);
      if (result) {
        const key = Hashing.PointKey(result);
        const typedPoint: IGridPoint = {
          ...result,
          Variety: VarietyChoiceByLines(
            cleanLines[i],
            cleanLines[j],
            interHash.get(key)
          )
        };
        interHash.set(key, typedPoint);
      }
    }
  }

  return Array.from(interHash.values());
}

export function VarietyChoiceByLines(
  a: IGridLine,
  b: IGridLine,
  oldHash: IGridPoint | undefined
): PointType {
  let result: PointType =
    oldHash === undefined ? PointType.Unknown : oldHash.Variety;
  if (!a.solid && !b.solid) {
    result = Math.max(result, PointType.GuideOnly);
  }
  if (a.solid && !b.solid) {
    result = Math.max(result, PointType.GuideToSolid);
  }
  if (!a.solid && b.solid) {
    result = Math.max(result, PointType.GuideToSolid);
  }
  if (a.solid && b.solid && hasEndpointMatch(a, b)) {
    result = Math.max(result, PointType.SolidVertex);
  }
  if (a.solid && b.solid && !hasEndpointMatch(a, b)) {
    result = Math.max(result, PointType.SolidBisection);
  }
  return result;
}
export function findAnyIntersection(a: IGridLine, b: IGridLine): IXY {
  const comparisonArray: IXY[] = [a.begin, a.end, b.begin, b.end];
  return findAnySegmentIntersection(comparisonArray);
}
export function findAnySegmentIntersection(comparisonArray: IXY[]): IXY {
  if (
    isEqualXY(comparisonArray[0], comparisonArray[2]) ||
    isEqualXY(comparisonArray[0], comparisonArray[3])
  ) {
    return comparisonArray[0];
  }
  if (
    isEqualXY(comparisonArray[1], comparisonArray[2]) ||
    isEqualXY(comparisonArray[1], comparisonArray[3])
  ) {
    return comparisonArray[1];
  }
  const unRounded = findSegmentIntersection(comparisonArray);
  if (!unRounded) {
    return unRounded;
  }
  const rounded = { x: Math.round(unRounded.x), y: Math.round(unRounded.y) };
  const normalZeroed: IXY = {
    x: rounded.x === 0 ? 0 : rounded.x,
    y: rounded.y === 0 ? 0 : rounded.y
  };
  return normalZeroed;
}
function hasEndpointMatch(a: IGridLine, b: IGridLine): boolean {
  const comparisonArray: IXY[] = [a.begin, a.end, b.begin, b.end];
  if (
    isEqualXY(comparisonArray[0], comparisonArray[2]) ||
    isEqualXY(comparisonArray[0], comparisonArray[3]) ||
    isEqualXY(comparisonArray[1], comparisonArray[2]) ||
    isEqualXY(comparisonArray[1], comparisonArray[3])
  ) {
    return true;
  }

  return false;
}
function isEqualXY(a: IXY, b: IXY): boolean {
  return a.x === b.x && a.y === b.y;
}

export function FindQuarterInchPoints(inches: number): IGridPoint[] {
  return FindQuarterInchPointsGeneric(inches, BOUND_MIN, BOUND_MAX);
}

export function FindQuarterInchPointsGeneric(
  inches: number,
  minBounds: number,
  maxBounds: number
): IGridPoint[] {
  const returnArray: IGridPoint[] = [];
  const gap = maxBounds / inches / 4;
  const spacingRanges: number[] = Array.from({
    length: ticksForInches(inches)
  }).map((v, i) => i * gap);
  //Top horizontal row
  returnArray.push(
    ...spacingRanges.map((gap) => {
      return { x: minBounds, y: gap, Variety: PointType.GuideOnly };
    })
  );
  //bottom horizontal row
  returnArray.push(
    ...spacingRanges.map((gap) => {
      return { x: maxBounds, y: gap, Variety: PointType.GuideOnly };
    })
  );
  const midSpaces = spacingRanges.filter(
    (x) => x !== minBounds && x !== maxBounds
  );
  // left column, minus corners
  returnArray.push(
    ...midSpaces.map((gap) => {
      return { x: gap, y: minBounds, Variety: PointType.GuideOnly };
    })
  );
  // right side minus boarders
  returnArray.push(
    ...midSpaces.map((gap) => {
      return { x: gap, y: maxBounds, Variety: PointType.GuideOnly };
    })
  );
  return returnArray;
}

export function ticksForInches(inch: number): number {
  return 4 * inch + 1;
}
