/** @format */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
import Ruler from "./Ruler";
interface IGridProps {
  blockStore?: IRootBlock;
}

export const RulerDynamic = inject("blockStore")(
  observer(
    class DynaRuler extends React.Component<IGridProps> {
      constructor(props, context) {
        super(props, context);
      }

      render() {
        const rulerProps = { inches: this.props.blockStore!.Pref.inches };
        return <Ruler {...rulerProps} />;
      }
    }
  )
);
