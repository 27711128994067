/** @format */

import * as React from "react";
import IGridPolygon from "../types/IGridPolygon";

import IXY from "../types/IXY";
export interface IRegionProps {
  poly: IGridPolygon;
  color: string;
  transform?: string;
}
class Region extends React.Component<IRegionProps> {
  constructor(props: IRegionProps) {
    super(props);
  }

  formatPathPoints(points: IXY[]): string {
    return points.map((p) => p.x + "," + p.y).join(" ");
  }
  render() {
    return (
      <g className="region">
        <polygon
          fill={this.props.color}
          points={this.formatPathPoints(this.props.poly.points)}
          transform={this.props.transform}
        />
      </g>
    );
  }
}

export default Region;
