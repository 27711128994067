/** @format */

import * as React from "react";
import { IQuiltDimensions } from "./IQuiltDimensions";
/**  https://reactjs.org/docs/dom-elements.html#all-supported-svg-attributes */
class CalcOutput extends React.Component<IQuiltDimensions> {
  render() {
    const blockCount = this.props.blockCountX * this.props.blockCountY;
    const blockWidth = this.props.blockCountX * this.props.blockInches;
    const sashWidth = (this.props.blockCountX - 1) * this.props.interiorSashing;
    const borderWidth = 2 * this.props.borderInches;
    const totalWidth = blockWidth + sashWidth + borderWidth;

    const blockHeight = this.props.blockCountY * this.props.blockInches;
    const sashHeight =
      (this.props.blockCountY - 1) * this.props.interiorSashing;
    const borderHeight = 2 * this.props.borderInches;
    const totalHeight = blockHeight + sashHeight + borderHeight;

    const squareInchesPerYard = 40 * 36;
    const calcBlockArea =
      (this.props.blockInches + 0.5) *
      (this.props.blockInches + 0.5) *
      blockCount;
    const calcBlockYardage = calcBlockArea / squareInchesPerYard;

    const calcBackingArea = (totalWidth + 0.5) * (totalHeight + 0.5);
    const calcBackingYardage = calcBackingArea / squareInchesPerYard;

    const calcBorderArea =
      (totalWidth * 2 + totalHeight * 2 + 1) * (this.props.borderInches + 0.5);
    const calcBorderYardage = calcBorderArea / squareInchesPerYard;

    return (
      <div>
        <p>Total number of blocks: {blockCount}</p>

        <p>Overall Width: {totalWidth} inches</p>

        <p>Overall Height: {totalHeight} inches</p>
        <p>Estimated yardage for blocks: {calcBlockYardage} yards</p>
        <p>Estimated yardage for border: {calcBorderYardage} yards</p>
        <p>Estimated yardage for backing: {calcBackingYardage} yards</p>
      </div>
    );
  }
}

export default CalcOutput;
