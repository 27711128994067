/** @format */

import * as React from "react";
import DotHandle from "./DotHandle";
import { PointKey } from "../math/Hashing";
export interface IDotProps {
  x: number;
  y: number;
}

const outlineColor = "rgb(22, 90, 201)";
const innerColor = "rgb(60, 126, 234)";
const radius = 1;

export default function Dot(props: IDotProps) {
  return (
    <g className="dot">
      <circle fill={outlineColor} r={radius * 3} cx={props.x} cy={props.y} />
      <circle fill={innerColor} cx={props.x} cy={props.y} r={radius} />
      <DotHandle
        key={PointKey({ x: props.x, y: props.y })}
        id={PointKey({ x: props.x, y: props.y })}
        x={props.x}
        y={props.y}
      />
    </g>
  );
}
