/** @format */

import * as React from "react";
import IXY from "../types/IXY";
import ICoordPair from "../types/ICoordPair";
import IGridLine from "../types/IGridLine";
import Dot from "./Dot";
import { AllDots } from "./AllDots";
import { AllRegions } from "./AllRegions";
import { StaticLines } from "./StaticLines";
import { IRootBlock } from "../store/block/IStores";
import { DynamicLine } from "./DynamicLine";
import { inject } from "mobx-react";
import { OutlineSolidLines, quarterGridLines } from "./Bounds";
import { RulerDynamic } from "./RulerDynamic";
interface IGridProps {
  blockStore?: IRootBlock;
}

@inject("blockStore")
class Grid extends React.Component<IGridProps> {
  SvgElement: SVGSVGElement | null;
  constructor(props) {
    super(props);
    this.SvgElement = null;

    this.mouseMove = this.mouseMove.bind(this);
    this.mouseClick = this.mouseClick.bind(this);
    this.mouseRelease = this.mouseRelease.bind(this);
    this.mouseContext = this.mouseContext.bind(this);
    this.mouseMiddle = this.mouseMiddle.bind(this);

    this.seed();
  }
  seed() {
    for (const line of quarterGridLines(this.props.blockStore!.Pref.inches)) {
      this.props.blockStore!.Graphic.addLine(line);
    }
    for (const line of OutlineSolidLines()) {
      this.props.blockStore!.Graphic.addLine(line);
    }
  }

  mouseMove(e) {
    const xy: IXY = { x: e.pageX, y: e.pageY };
    console.log("MOVE: " + xy.x + ", " + xy.y);

    const pt: DOMPoint = this.SvgElement!.createSVGPoint();
    let svgP;
    pt.x = e.pageX;
    pt.y = e.pageY;
    svgP = pt.matrixTransform(this.SvgElement!.getScreenCTM()!.inverse());

    this.props.blockStore!.Input.setDrag({ x: svgP.x, y: svgP.y });
  }

  mouseClick(e) {
    const pt: DOMPoint = this.SvgElement!.createSVGPoint();
    pt.x = e.pageX;
    pt.y = e.pageY;
    const svgP: DOMPoint = pt.matrixTransform(
      this.SvgElement!.getScreenCTM()!.inverse()
    );
    const xy: IXY = { x: svgP.x, y: svgP.y };

    console.log("CLICK: " + xy.x + ", " + xy.y);
    this.props.blockStore!.Input.setClick({
      position: xy,
      shift: e.shiftKey,
      alt: e.altKey,
      ctrl: e.ctrlKey,
      middle: e.button === 1,
      right: e.button === 2
    });
  }
  mouseRelease() {
    console.log("RELEASE");
    this.props.blockStore!.Input.setRelease();
  }
  mouseContext(e) {
    e.preventDefault();
  }
  mouseMiddle(e) {
    e.preventDefault();
  }
  render() {
    return (
      <svg
        ref={(input) => {
          this.SvgElement = input;
        }}
        onMouseUp={this.mouseRelease}
        onMouseLeave={this.mouseRelease}
        onMouseMove={this.mouseMove}
        onMouseDown={this.mouseClick}
        onContextMenu={this.mouseContext}
        width={600}
        height={600}
        role="img"
        viewBox="-50 -50 1100 1100"
        preserveAspectRatio="xMidYMid meet"
      >
        <AllRegions />
        <StaticLines />
        <DynamicLine />
        <RulerDynamic />
        <AllDots />
      </svg>
    );
  }
}

export default Grid;
