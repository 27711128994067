/** @format */

// https://math.stackexchange.com/questions/1524213/what-would-be-the-most-efficient-way-to-detect-all-closed-paths-in-a-collection
import ICoordPair from "../types/ICoordPair";
import IXY from "../types/IXY";
import IGraph from "../types/IGraph";
import IHashPolygon from "../types/IHashPolygon";
import IGridPolygon from "../types/IGridPolygon";
import * as Gmaker from "./GraphMaker";
import * as AngDet from "./AngleDetection";
export function FindAllPolygons(inputSegments: ICoordPair[]): IGridPolygon[] {
  let workingGraph = Gmaker.CreateGraph(inputSegments);
  workingGraph = Gmaker.MinusDangler(workingGraph);
  const detectedHashPolys = FindPolygonsByHash(workingGraph);
  const gridPolys: IGridPolygon[] = detectedHashPolys.map((x: IHashPolygon) =>
    ConvertHashPolyToGridPoly(x, workingGraph)
  );
  return gridPolys;
}
function ConvertHashPolyToGridPoly(
  hashPoly: IHashPolygon,
  graph: IGraph
): IGridPolygon {
  const newPoints: IXY[] = hashPoly.points.map((x) => graph.vertices.get(x)!);
  return { points: newPoints };
}
function FindPolygonsByHash(graph: IGraph): IHashPolygon[] {
  let workingGraph = graph;
  const returnPolyarray: IHashPolygon[] = [];
  let nextpoly: string[] = [];
  do {
    nextpoly = DetectPoly(workingGraph);
    if (nextpoly.length > 0) {
      workingGraph = RemoveDirectedPoly(workingGraph, nextpoly);
      returnPolyarray.push({ points: nextpoly });
    }
  } while (nextpoly.length > 0 && workingGraph.adjacency.size > 0);

  return returnPolyarray;
}

export function DetectPoly(graph: IGraph): string[] {
  const polyStack: string[] = [];

  // don't know if this is the best way to pick a startKey...
  const startKey: string = Array.from(graph.adjacency.keys())[0];
  polyStack.push(startKey);
  const startAdjVerticies = graph.adjacency.get(startKey)!;
  const secondKey: string = startAdjVerticies[0];
  polyStack.push(secondKey);

  return RecurseDetectPoly(graph, polyStack);
}

function RecurseDetectPoly(graph: IGraph, polyStack: string[]): string[] {
  if (polyStack.length < 2) {
    // assume that polystack has at least two members
    return [];
  }
  const firstKey = polyStack[0];
  const nextToLastKey = polyStack[polyStack.length - 2];
  const lastKey = polyStack[polyStack.length - 1];

  const next = AngDet.FindNextClockwiseVertex(nextToLastKey, lastKey, graph);
  if (!next) {
    return [];
  }
  const newStack = polyStack.slice();
  newStack.push(next);
  if (next === firstKey) {
    return newStack;
  }
  return RecurseDetectPoly(graph, newStack);
}

export function RemoveDirectedPoly(
  graph: IGraph,
  directedPoly: string[]
): IGraph {
  let currentGraph = graph;
  let i: number = 0;
  for (i = 0; i < directedPoly.length; i++) {
    const start = directedPoly[i];
    const end = directedPoly[i + 1];
    currentGraph = RemoveAdjacency(currentGraph, start, end);
  }
  currentGraph = RemoveUnusedVerticies(currentGraph);
  return currentGraph;
}
function RemoveAdjacency(graph: IGraph, start: string, end: string): IGraph {
  const originalEnds: string[] = graph.adjacency.get(start)!;
  const copyAdj = new Map(graph.adjacency);
  copyAdj.set(start, originalEnds.filter((x) => x !== end));
  return { vertices: graph.vertices, adjacency: copyAdj };
}
export function RemoveUnusedVerticies(graph: IGraph): IGraph {
  const copyAdj = new Map<string, string[]>();
  graph.adjacency.forEach((v, k) => {
    if (v.length > 0) {
      copyAdj.set(k, v);
    }
  });
  return { vertices: graph.vertices, adjacency: copyAdj };
}
