/** @format */

import * as React from "react";
import { IExperimentState } from "./IExperimentState";

/**  https://reactjs.org/docs/dom-elements.html#all-supported-svg-attributes */
class ExperimentTab extends React.Component<{}, IExperimentState> {
  constructor(props: any) {
    super(props);
    this.state = {
      testBool: true,
      testString: "test"
    };
  }

  render() {
    return (
      <div>
        <p>This tab will be for experimentation and new development</p>
      </div>
    );
  }
}

export default ExperimentTab;
