/** @format */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
import ICoordPair from "../types/ICoordPair";
import IXY from "../types/IXY";
import Region from "../dotGrid/Region";
import IColorPolygon from "../types/IColorPolygon";
interface IGridProps {
  regions: IColorPolygon[];
  colors: string[];
  transform: string;
}

export class ColoredRegions extends React.Component<IGridProps> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <g className="colored-regions">
        {this.props.regions.map((ipoly, i) => (
          <Region
            transform={this.props.transform}
            color={this.props.colors[ipoly.fabric]}
            poly={ipoly}
            key={i}
          />
        ))}
      </g>
    );
  }
}
