/** @format */

import * as React from "react";
import ICoordPair from "../types/ICoordPair";
import * as Bounds from "../dotGrid/Bounds";
import * as Hashing from "../math/Hashing";
import LineHandle from "./LineHandle";
export default function LineSolid(coordPair: ICoordPair) {
  const pathD = `M ${coordPair.begin.x} ${coordPair.begin.y} L ${
    coordPair.end.x
  } ${coordPair.end.y}`;
  const isEdge = Bounds.IsEdgeSegment(coordPair);
  const lineKey = Hashing.LineKey(coordPair);
  const handleElement = isEdge ? null : (
    <LineHandle key={lineKey + "-handle"} id={lineKey} path={pathD} />
  );
  return (
    <g className="solidLine">
      <path
        key={lineKey}
        d={pathD}
        stroke="black"
        strokeLinecap="round"
        strokeWidth="3"
        fill="black"
      />
      {handleElement}
    </g>
  );
}
