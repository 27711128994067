/** @format */

import React from "react";
import clrs from "colors.css";
import { IRootBlock } from "../store/block/IStores";
import { inject, observer } from "mobx-react";
import ICoordPair from "../types/ICoordPair";
export interface IHandleProps {
  id: any;
  path: string;
  blockStore?: IRootBlock;
}

const basicWidth = 7;
const expandedWidth = 13;
@inject("blockStore")
@observer
class LineHandle extends React.Component<IHandleProps> {
  constructor(props, context) {
    super(props, context);

    this.hover = this.hover.bind(this);
    this.unhover = this.unhover.bind(this);
  }

  hover(e: any) {
    this.props.blockStore!.Hover.setSegEnter(this.props.id);
  }
  unhover(e: any) {
    this.props.blockStore!.Hover.setSegLeave();
  }

  render() {
    if (!this.props.blockStore!.Hover.activeHoverSeg) {
      return null;
    }
    return (
      <path
        className="lineHandle"
        id={this.props.id}
        d={this.props.path}
        stroke="grey"
        strokeLinecap="round"
        strokeWidth={
          this.props!.blockStore!.Hover.hoveringSeg === this.props.id
            ? expandedWidth
            : basicWidth
        }
        strokeOpacity={0.3}
        onMouseOver={this.hover}
        onMouseLeave={this.unhover}
      />
    );
  }
}

export default LineHandle;
