/** @format */

import ICoordPair from "../types/ICoordPair";
import IXY from "../types/IXY";
import IGraph from "../types/IGraph";
import * as Hashing from "./Hashing";
export function CreateGraph(inputSegments: ICoordPair[]): IGraph {
  const toReturn: IGraph = {
    vertices: new Map<string, IXY>(),
    adjacency: new Map<string, string[]>()
  };

  inputSegments.forEach((x) => {
    const key1 = Hashing.PointKey(x.begin);
    const key2 = Hashing.PointKey(x.end);
    toReturn.vertices.set(key1, x.begin);
    toReturn.vertices.set(key2, x.end);

    const beginOld: string[] | undefined = toReturn.adjacency.get(key1);
    const beginUpdated: string[] = Object.is(beginOld, undefined)
      ? [key2]
      : beginOld!.concat(key2);
    toReturn.adjacency.set(key1, beginUpdated);

    const endOld: string[] | undefined = toReturn.adjacency.get(key2);
    const endUpdated: string[] = Object.is(endOld, undefined)
      ? [key1]
      : endOld!.concat(key1);
    toReturn.adjacency.set(key2, endUpdated);
  });
  return toReturn;
}
export function ToCoordPairs(inputGraph: IGraph): ICoordPair[] {
  const keyPairs: IKeyPair[] = [];
  const keys: string[] = Array.from(inputGraph.adjacency.keys());
  keys.forEach((v1) => {
    const xadj = inputGraph.adjacency.get(v1);
    xadj!.forEach((v2) => {
      keyPairs.push(OrderVertexPair(v1, v2));
    });
  });

  const joinedStringWithDupes: string[] = keyPairs.map((x) => x.a + "*" + x.b);
  const uniqueString: string[] = joinedStringWithDupes.filter(
    (v, i, a) => a.indexOf(v) === i
  );
  const uniqueKeyPairs: IKeyPair[] = uniqueString.map((x) =>
    SplitJoinedKeys(x)
  );
  const toReturn: ICoordPair[] = uniqueKeyPairs.map((x) => {
    let s: IXY = { x: 0, y: 0 };
    let e: IXY = { x: 0, y: 0 };
    s = inputGraph.vertices.get(x.a)!;
    e = inputGraph.vertices.get(x.b)!;
    return { begin: s, end: e };
  });

  return toReturn;
}
function SplitJoinedKeys(joinedKey: string): IKeyPair {
  const v = joinedKey.split("*");
  return OrderVertexPair(v[0], v[1]);
}
function OrderVertexPair(j: string, k: string): IKeyPair {
  if (j > k) {
    return { a: j, b: k };
  } else {
    return { a: k, b: j };
  }
}
interface IKeyPair {
  a: string;
  b: string;
}

export function MinusDangler(input: IGraph): IGraph {
  const deadVertices: string[] = [];
  input.adjacency.forEach((v, k) => {
    if (v.length <= 1) {
      deadVertices.push(k);
    }
  });

  if (deadVertices.length === 0) {
    return input;
  }
  const newVertices: Map<string, IXY> = new Map();
  input.vertices.forEach((v, k) => {
    if (!include(k, deadVertices)) {
      newVertices.set(k, v);
    }
  });

  const newAdjacency: Map<string, string[]> = new Map();
  input.adjacency.forEach((v, k) => {
    // if this is not a dead vertex, add it to newAdjacency
    if (!include(k, deadVertices)) {
      const filteredArray: string[] = v.filter(
        (x) => !include(x, deadVertices)
      );
      newAdjacency.set(k, filteredArray);
    }
  });

  const smallerSet = { vertices: newVertices, adjacency: newAdjacency };
  return MinusDangler(smallerSet);
}
function include(needle, haystack) {
  return haystack.indexOf(needle) !== -1;
}
