/** @format */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
import Region from "./Region";
import { BOUND_MIN, BOUND_MAX } from "./Bounds";

import ICoordPair from "../types/ICoordPair";

import IXY from "../types/IXY";
import IGridPolygon from "../types/IGridPolygon";
interface IGridProps {
  blockStore?: IRootBlock;
}

export const ThumbnailRegions = inject("blockStore")(
  observer(
    class AllR extends React.Component<IGridProps> {
      constructor(props, context) {
        super(props, context);
      }

      render() {
        return (
          <table>
            <tbody>
              <tr>
                <th>Region List</th>
              </tr>
              {this.props.blockStore!.Graphic.detectedPolygons.map(
                (ipoly, i) => (
                  <tr key={"row-region-" + i}>
                    <td>
                      <div>
                        <svg
                          width={50}
                          height={50}
                          viewBox={
                            BOUND_MIN +
                            " " +
                            BOUND_MIN +
                            " " +
                            BOUND_MAX +
                            " " +
                            BOUND_MAX
                          }
                          role="img"
                        >
                          <g className="thumb-region">
                            <Region
                              color={"red"}
                              poly={ipoly}
                              key={"thumb-reg-" + i}
                            />
                          </g>
                        </svg>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        );
      }
    }
  )
);
