/** @format */
import * as React from "react";
import IXY from "../types/IXY";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
interface IGridProps {
  blockStore?: IRootBlock;
}

export const DynamicLine = inject("blockStore")(
  observer(
    class DynaLine extends React.Component<IGridProps> {
      constructor(props, context) {
        super(props, context);
      }

      render() {
        console.log(this.props);
        if (this.props.blockStore!.Input.dragging) {
          return (
            <line
              className="dynamic-line"
              x1={this.props.blockStore!.Input.dragStartPoint!.x}
              y1={this.props.blockStore!.Input.dragStartPoint!.y}
              x2={this.props.blockStore!.Input.dragEndPoint!.x}
              y2={this.props.blockStore!.Input.dragEndPoint!.y}
              stroke="black"
            />
          );
        } else {
          return null;
        }
      }
    }
  )
);
