/** @format */
import * as React from "react";
import LineDashed from "./LineDashed";
import LineSolid from "./LineSolid";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
import ICoordPair from "../types/ICoordPair";
interface IGridProps {
  blockStore?: IRootBlock;
}

export const StaticLines = inject("blockStore")(
  observer(
    class SLine extends React.Component<IGridProps> {
      constructor(props, context) {
        super(props, context);
      }

      thickLine(coordPair: ICoordPair) {
        const pathD = `M ${coordPair.begin.x} ${coordPair.begin.y} L ${
          coordPair.end.x
        } ${coordPair.end.y}`;
        console.log("should be rendering a thick line...");
        const tKey =
          "s-" +
          coordPair.begin.x +
          "-" +
          coordPair.begin.y +
          "-" +
          coordPair.end.x +
          "-" +
          coordPair.end.y;
        return (
          <path
            key={tKey}
            d={pathD}
            stroke="black"
            strokeLinecap="round"
            strokeWidth="3"
            fill="black"
          />
        );
      }
      render() {
        return (
          <g className="static-lines">
            <g className="dashed-lines">
              {this.props!.blockStore!.Graphic.guideGridLines.map((pair, i) => (
                <LineDashed key={i} {...pair} />
              ))}
            </g>
            <g className="thick-lines">
              {this.props!.blockStore!.Graphic.solidGridLines.map((pair, i) => (
                <LineSolid key={i} {...pair} />
              ))}
            </g>
          </g>
        );
      }
    }
  )
);
