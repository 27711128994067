/** @format */

import IXY from "./IXY";

export default interface IGridPoint extends IXY {
  Variety: PointType;
}

export enum PointType {
  Unknown = 0,
  GuideOnly = 1,
  GuideToSolid = 2,
  SolidIsolated = 4,
  SolidVertex = 8,
  SolidBisection = 16
}
