/** @format */

import * as React from "react";
import { IQuiltDimensions } from "./IQuiltDimensions";
import Outline from "./Outline";
import CalcOutput from "./CalcOutput";
import { MenuBlocks } from "./MenuBlocks";
import * as PotentialBlockList from "./PotentialBlockList";
/**  https://reactjs.org/docs/dom-elements.html#all-supported-svg-attributes */
class InputSize extends React.Component<{}, IQuiltDimensions> {
  constructor(props: any) {
    super(props);
    this.state = {
      blockCountX: 4,
      blockCountY: 3,
      blockInches: 9,
      borderInches: 3,
      interiorSashing: 1
    };
    // This binding is necessary to make `this` work in the callback
    this.handleBlockXChange = this.handleBlockXChange.bind(this);
    this.handleBlockYChange = this.handleBlockYChange.bind(this);
    this.handleBlockInchesChange = this.handleBlockInchesChange.bind(this);
    this.handleBorderInchesChange = this.handleBorderInchesChange.bind(this);
    this.handleSashingInchesChange = this.handleSashingInchesChange.bind(this);
  }
  handleBlockXChange(event) {
    this.setState({ blockCountX: event.target.value });
  }
  handleBlockYChange(event) {
    this.setState({ blockCountY: event.target.value });
  }
  handleBlockInchesChange(event) {
    this.setState({ blockInches: event.target.value });
  }
  handleBorderInchesChange(event) {
    this.setState({ borderInches: event.target.value });
  }
  handleSashingInchesChange(event) {
    this.setState({ interiorSashing: event.target.value });
    console.log(this.state);
  }
  render() {
    return (
      <div>
        <span>Horizontal Blocks:</span>
        <input
          type="text"
          name="blockCountX"
          value={this.state.blockCountX}
          onChange={this.handleBlockXChange}
        />
        <br />
        <span>Vertical Blocks:</span>
        <input
          type="text"
          name="blockCountY"
          value={this.state.blockCountY}
          onChange={this.handleBlockYChange}
        />
        <br />
        <span>Block size (inches):</span>
        <input
          type="text"
          name="blockinch"
          value={this.state.blockInches}
          onChange={this.handleBlockInchesChange}
        />
        <br />
        <span>Border size (inches):</span>
        <input
          type="text"
          name="borderinches"
          value={this.state.borderInches}
          onChange={this.handleBorderInchesChange}
        />
        <br />
        <span>Sashing Width (inches):</span>
        <input
          type="text"
          name="sashinginches"
          value={this.state.interiorSashing}
          onChange={this.handleSashingInchesChange}
        />
        <br />
        <Outline {...this.state} />
        <MenuBlocks blockList={PotentialBlockList.All} />
        <CalcOutput {...this.state} />
      </div>
    );
  }
}

export default InputSize;
