/** @format */

import { observable, action, computed, toJS } from "mobx";
import IXY from "../../types/IXY";
import * as Stores from "./IStores";
export class PrefStore implements Stores.IPref {
  rootStore: Stores.IRootBlock;
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable inches: number = 6;
  @action
  setInches = (inchCount: number) => {
    this.inches = inchCount;
  };
}
