/** @format */
import IXY from "../types/IXY";
import IGridLine from "../types/IGridLine";
import ICoordPair from "../types/ICoordPair";

export function PointKey(point: IXY): string {
  return point.x + "#" + point.y;
}
export function PointReverse(inputKey: string): IXY {
  const splitKeys = inputKey.split("#", 2);
  return {
    x: parseInt(splitKeys[0], 10),
    y: parseInt(splitKeys[1], 10)
  };
}

export function LineKey(line: ICoordPair): string {
  return PointKey(line.begin) + "^" + PointKey(line.end);
}
export function LineReverse(inputKey: string): ICoordPair {
  const splitKeys = inputKey.split("^", 2);
  return {
    begin: PointReverse(splitKeys[0]),
    end: PointReverse(splitKeys[1])
  };
}
