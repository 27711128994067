/** @format */

import { observable, action, computed, toJS } from "mobx";
import * as Stores from "./IStores";
import { InputStore } from "./InputStore";
import { HoverStore } from "./HoverStore";
import { GraphicStore } from "./GraphicStore";
import { PrefStore } from "./PrefStore";
export class RootBlockStore implements Stores.IRootBlock {
  Input: Stores.IInput;
  Hover: Stores.IHover;
  Graphic: Stores.IGraphic;
  Pref: Stores.IPref;
  constructor() {
    this.Input = new InputStore(this);
    this.Hover = new HoverStore(this);
    this.Graphic = new GraphicStore(this);
    this.Pref = new PrefStore(this);
  }
}
