/** @format */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
import ICoordPair from "../types/ICoordPair";
import Dot from "./Dot";
import IXY from "../types/IXY";
import Region from "./Region";
import IGridPolygon from "../types/IGridPolygon";
interface IGridProps {
  blockStore?: IRootBlock;
}

export const AllRegions = inject("blockStore")(
  observer(
    class AllR extends React.Component<IGridProps> {
      constructor(props, context) {
        super(props, context);
      }
      getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
      }
      randomColor(): string {
        return (
          "rgba(" +
          this.getRandomInt(255) +
          "," +
          this.getRandomInt(255) +
          "," +
          this.getRandomInt(255) +
          ",0.4)"
        );
      }
      render() {
        return (
          <g className="all-regions">
            {this.props.blockStore!.Graphic.detectedPolygons.map((ipoly, i) => (
              <Region color={this.randomColor()} poly={ipoly} key={i} />
            ))}
          </g>
        );
      }
    }
  )
);
/*
  {this.props.blockStore!.detectedPolygons.map((coord, i) => (
              <Region {...coord} key={i} />
            ))}

*/
