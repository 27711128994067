/** @format */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { IRootBlock } from "../store/block/IStores";
import ICoordPair from "../types/ICoordPair";
import Dot from "./Dot";
import IXY from "../types/IXY";
interface IGridProps {
  blockStore?: IRootBlock;
}

export const AllDots = inject("blockStore")(
  observer(
    class AllD extends React.Component<IGridProps> {
      constructor(props, context) {
        super(props, context);
      }

      render() {
        return (
          <g className="all-dots">
            <g className="edge-quarter-inch-dots">
              {this.props.blockStore!.Graphic.edgeRulerPoints.map((coord) => (
                <Dot {...coord} key={coord.x * 10000 + coord.y} />
              ))}
            </g>
            <g className="intersection-dots">
              {this.props.blockStore!.Graphic.intersectionPointsAll.map(
                (coord) => (
                  <Dot {...coord} key={coord.x * 10000 + coord.y} />
                )
              )}
            </g>
          </g>
        );
      }
    }
  )
);
